var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("q-form", { ref: "editForm" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: {
                  title: "유해위험기계기구 배치도",
                  noMarginPadding: true,
                  height: _vm.imgHeight,
                },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "일괄 저장", icon: "save" },
                            })
                          : _vm._e(),
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "지도 변경", icon: "place" },
                              on: { btnClicked: _vm.selectMap },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { ref: "parent", attrs: { id: "parent" } }, [
                      _vm.setComplete
                        ? _c(
                            "div",
                            { style: { height: _vm.imgHeight } },
                            [
                              _c(
                                "VueDraggableResizable",
                                {
                                  ref: "markImage",
                                  staticClass: "my-class",
                                  attrs: {
                                    parent: true,
                                    draggable: _vm.editable,
                                    resizable: _vm.editable,
                                    "class-name-dragging": "my-dragging-class",
                                    x: _vm.data.locationX,
                                    y: _vm.data.locationY,
                                    w: _vm.data.locationW,
                                    h: _vm.data.locationH,
                                    grid: [10, 10],
                                    minHeight: 20,
                                    minWidth: 20,
                                  },
                                  on: {
                                    dragging: (x, y) =>
                                      _vm.onDrag(_vm.data, x, y),
                                    resizing: (x, y, w, h) =>
                                      _vm.onResize(_vm.data, x, y, w, h),
                                  },
                                },
                                [
                                  _c("div", { staticClass: "loc-header" }, [
                                    _vm._v(_vm._s(_vm.data.workName)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }